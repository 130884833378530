var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.impr.ibmStepCd !== "IS00000001",
            expression: "impr.ibmStepCd !== 'IS00000001'",
          },
        ],
        staticClass: "col-12",
      },
      [
        _c("c-step", {
          attrs: {
            stepperGrpCd: "IBM_STEP_CD",
            currentStepCd: _vm.impr.ibmStepCd,
            disabled: true,
          },
          model: {
            value: _vm.impr.ibmStepCd,
            callback: function ($$v) {
              _vm.$set(_vm.impr, "ibmStepCd", $$v)
            },
            expression: "impr.ibmStepCd",
          },
        }),
      ],
      1
    ),
    _vm.overFlag &&
    this.impr.approvalStatusCd !== "ASC9999999" &&
    _vm.impr.delayFlag !== "Y"
      ? _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "q-banner",
              {
                staticClass: "text-white bg-red",
                attrs: { dense: "", "inline-actions": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "avatar",
                      fn: function () {
                        return [
                          _c("q-icon", {
                            attrs: { name: "report_problem", color: "grey-3" },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  407695491
                ),
              },
              [_c("b", { staticClass: "text-h5" }, [_vm._v("지연")])]
            ),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "q-form",
              { ref: "editForm1" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "요청&접수 상세" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _c(_vm.relationTableInfo.component, {
                              key: _vm.relationTableInfo.key,
                              tag: "component",
                              attrs: {
                                taskParam: _vm.relationTableInfo.taskParam,
                                disabled: !Boolean(
                                  _vm.popupParam.sopImprovementId
                                ),
                              },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.requestCancelBtnEditable,
                                  expression: "requestCancelBtnEditable",
                                },
                              ],
                              attrs: {
                                isSubmit: _vm.isReject,
                                url: _vm.saveUrl,
                                param: _vm.impr,
                                mappingType: "PUT",
                                label: "요청취소",
                                icon: "delete_forever",
                              },
                              on: {
                                beforeAction: _vm.rejectImpr,
                                btnCallback: _vm.rejectCallback,
                              },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.requestAreaEditable &&
                                    _vm.popupParam.sopImprovementId,
                                  expression:
                                    "requestAreaEditable&&popupParam.sopImprovementId",
                                },
                              ],
                              attrs: {
                                isSubmit: _vm.isDelete,
                                url: _vm.deleteUrl,
                                param: _vm.impr,
                                mappingType: "DELETE",
                                label: "삭제",
                                icon: "remove",
                              },
                              on: {
                                beforeAction: _vm.removeImpr,
                                btnCallback: _vm.removeCallback,
                              },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.saveBtnEditable1,
                                  expression: "saveBtnEditable1",
                                },
                              ],
                              attrs: {
                                isSubmit: _vm.isSaveRequestReceipt,
                                url: _vm.saveUrl,
                                param: _vm.impr,
                                mappingType: _vm.mappingType,
                                label: "저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveRequestReceiptImpr,
                                btnCallback: _vm.saveRequestReceiptCallback,
                              },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.requestBtnEditable,
                                  expression: "requestBtnEditable",
                                },
                              ],
                              attrs: {
                                isSubmit: _vm.isRequest,
                                url: _vm.saveUrl,
                                param: _vm.impr,
                                mappingType: "PUT",
                                label: "조치부서에 요청",
                                icon: "send",
                                color: "red",
                              },
                              on: {
                                beforeAction: _vm.requestImpr,
                                btnCallback: _vm.requestCallback,
                              },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.receiptBtnEditable,
                                  expression: "receiptBtnEditable",
                                },
                              ],
                              attrs: {
                                isSubmit: _vm.isReceipt,
                                url: _vm.saveUrl,
                                param: _vm.impr,
                                mappingType: "PUT",
                                label: "접수",
                                icon: "directions_run",
                                color: "red",
                              },
                              on: {
                                beforeAction: _vm.receiptImpr,
                                btnCallback: _vm.receiptCallback,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-3" },
                        [
                          _c("c-select", {
                            attrs: {
                              disabled: true,
                              editable: _vm.editable,
                              comboItems: _vm.taskComboItems,
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "ibmTaskTypeCd",
                              label: "업무",
                            },
                            model: {
                              value: _vm.impr.ibmTaskTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.impr, "ibmTaskTypeCd", $$v)
                              },
                              expression: "impr.ibmTaskTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-3" },
                        [
                          _c("c-text", {
                            attrs: {
                              disabled: true,
                              editable: _vm.editable,
                              name: "sopImprovementId",
                              label: "개선번호",
                            },
                            model: {
                              value: _vm.impr.sopImprovementId,
                              callback: function ($$v) {
                                _vm.$set(_vm.impr, "sopImprovementId", $$v)
                              },
                              expression: "impr.sopImprovementId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              disabled: true,
                              label: "요청부서/이름/요청일",
                              name: "request",
                              value: _vm.request,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.requestAreaEditable,
                              required: true,
                              label: "제목",
                              name: "ibmTitle",
                            },
                            model: {
                              value: _vm.impr.ibmTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.impr, "ibmTitle", $$v)
                              },
                              expression: "impr.ibmTitle",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.requestAreaEditable,
                              required: true,
                              label: "요청내용",
                              name: "improveRequestContents",
                            },
                            model: {
                              value: _vm.impr.improveRequestContents,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.impr,
                                  "improveRequestContents",
                                  $$v
                                )
                              },
                              expression: "impr.improveRequestContents",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-8" },
                              [
                                _c("c-plant", {
                                  attrs: {
                                    editable: _vm.requestAreaEditable,
                                    required: true,
                                    type: "edit",
                                    name: "plantCd",
                                  },
                                  model: {
                                    value: _vm.impr.plantCd,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.impr, "plantCd", $$v)
                                    },
                                    expression: "impr.plantCd",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-4" },
                              [
                                _c("c-checkbox", {
                                  attrs: {
                                    editable: false,
                                    isFlag: true,
                                    trueLabel: "업체",
                                    falseLabel: "부서",
                                    trueValue: "V",
                                    falseValue: "D",
                                    label: "",
                                    name: "actionFlag",
                                  },
                                  model: {
                                    value: _vm.impr.actionFlag,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.impr, "actionFlag", $$v)
                                    },
                                    expression: "impr.actionFlag",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
                        [
                          _vm.vendorCheck
                            ? [
                                _c("c-vendor", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.impr.actionFlag === "V",
                                      expression: "impr.actionFlag === 'V'",
                                    },
                                  ],
                                  attrs: {
                                    editable: false,
                                    type: "edit",
                                    label: "조치업체",
                                    name: "actionVendorCd",
                                  },
                                  model: {
                                    value: _vm.impr.actionVendorCd,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.impr, "actionVendorCd", $$v)
                                    },
                                    expression: "impr.actionVendorCd",
                                  },
                                }),
                              ]
                            : [
                                _c("c-vendor-other", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.impr.actionFlag === "V",
                                      expression: "impr.actionFlag === 'V'",
                                    },
                                  ],
                                  attrs: {
                                    editable: _vm.requestAreaEditable,
                                    required: _vm.impr.actionFlag === "V",
                                    selfFlag: false,
                                    type: "edit",
                                    label: "조치업체",
                                    name: "actionVendorCd",
                                  },
                                  model: {
                                    value: _vm.impr.actionVendorCd,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.impr, "actionVendorCd", $$v)
                                    },
                                    expression: "impr.actionVendorCd",
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              editable: _vm.requestAreaEditable,
                              required: true,
                              label: "조치완료 요청일",
                              name: "actionCompleteRequestDate",
                            },
                            model: {
                              value: _vm.impr.actionCompleteRequestDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.impr,
                                  "actionCompleteRequestDate",
                                  $$v
                                )
                              },
                              expression: "impr.actionCompleteRequestDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
                        [
                          _c("c-field", {
                            attrs: {
                              editable:
                                _vm.requestAreaEditable ||
                                _vm.actionReceiptAreaEditable,
                              type: "dept_user",
                              data: _vm.impr,
                              deptValue: "actionCompleteCheckDeptCd",
                              label: "조치완료 확인자",
                              name: "actionCompleteCheckUserId",
                            },
                            model: {
                              value: _vm.impr.actionCompleteCheckUserId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.impr,
                                  "actionCompleteCheckUserId",
                                  $$v
                                )
                              },
                              expression: "impr.actionCompleteCheckUserId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.actionReceiptAreaEditable,
                              label: "요청취소 사유",
                              name: "requestRejectReason",
                            },
                            model: {
                              value: _vm.impr.requestRejectReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.impr, "requestRejectReason", $$v)
                              },
                              expression: "impr.requestRejectReason",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-upload", {
                            attrs: {
                              attachInfo: _vm.attachBeforeInfo,
                              editable:
                                _vm.requestAreaEditable ||
                                _vm.actionReceiptAreaEditable ||
                                _vm.actionAreaEditable,
                              label: "개선 전 사진",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.isTargetReset &&
                  !(
                    _vm.impr.completeDateOver > 0 &&
                    _vm.impr.ibmStepCd !== "IS00000001"
                  ),
                expression:
                  "isTargetReset&&!(impr.completeDateOver > 0 && impr.ibmStepCd !== 'IS00000001')",
              },
            ],
            staticClass: "col-12",
          },
          [
            _c(
              "q-list",
              {
                staticClass: "impr-delay-list",
                attrs: { bordered: "", padding: "", dense: "" },
              },
              [
                _c(
                  "q-item",
                  {
                    directives: [{ name: "ripple", rawName: "v-ripple" }],
                    attrs: {
                      tag: "label",
                      active: _vm.impr.delayFlag === "Y",
                      "active-class": "impr-delay-active",
                    },
                  },
                  [
                    _c(
                      "q-item-section",
                      { attrs: { side: "", top: "" } },
                      [
                        _c("q-checkbox", {
                          attrs: { "true-value": "Y", "false-value": "N" },
                          model: {
                            value: _vm.impr.delayFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.impr, "delayFlag", $$v)
                            },
                            expression: "impr.delayFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", [_vm._v("목표일 재설정")]),
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            " ※ 재설정 시에 지연 프로세스가 동작하며, 재설정한 목표일을 넘길 시 지연 프로세스가 동작하지 않습니다. "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.overFlag,
                expression: "overFlag",
              },
            ],
            staticClass: "col-12",
          },
          [
            _c(
              "q-form",
              { ref: "overForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "지연상세" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.overEditable,
                                  expression: "overEditable",
                                },
                              ],
                              attrs: {
                                isSubmit: _vm.isOver,
                                url: _vm.saveUrl,
                                param: _vm.impr,
                                mappingType: "PUT",
                                label: "저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveOverImpr,
                                btnCallback: _vm.saveOverCallback,
                              },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.overEditable,
                                  expression: "overEditable",
                                },
                              ],
                              attrs: {
                                isSubmit: _vm.isOverComplete,
                                url: _vm.saveUrl,
                                param: _vm.impr,
                                mappingType: "PUT",
                                label: "목표일 설정 완료",
                                icon: "check",
                              },
                              on: {
                                beforeAction: _vm.approvalOverImpr,
                                btnCallback: _vm.approvalOverImrpCallback,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              editable: _vm.overEditable,
                              required: true,
                              start: _vm.impr.actionCompleteRequestDate,
                              label: "목표일",
                              name: "targetDate",
                            },
                            model: {
                              value: _vm.impr.targetDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.impr, "targetDate", $$v)
                              },
                              expression: "impr.targetDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.overEditable,
                              required: true,
                              label: "지연사유",
                              name: "delayReason",
                            },
                            model: {
                              value: _vm.impr.delayReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.impr, "delayReason", $$v)
                              },
                              expression: "impr.delayReason",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "q-form",
              { ref: "editForm2" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "조치상세" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.actionCompleteBtnEditable,
                                  expression: "actionCompleteBtnEditable",
                                },
                              ],
                              attrs: {
                                isSubmit: _vm.isComplete,
                                url: _vm.saveUrl,
                                param: _vm.impr,
                                mappingType: "PUT",
                                label: "개선완료",
                                icon: "check_circle_outline",
                              },
                              on: {
                                beforeAction: _vm.completeImpr,
                                btnCallback: _vm.completeCallback,
                              },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.saveBtnEditable2,
                                  expression: "saveBtnEditable2",
                                },
                              ],
                              attrs: {
                                isSubmit: _vm.isSaveAction,
                                url: _vm.saveUrl,
                                param: _vm.impr,
                                mappingType: "PUT",
                                label: "저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveActionImpr,
                                btnCallback: _vm.saveActionCallback,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              editable: _vm.actionAreaEditable,
                              required: true,
                              label: "조치완료일",
                              name: "actionCompleteDate",
                            },
                            model: {
                              value: _vm.impr.actionCompleteDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.impr, "actionCompleteDate", $$v)
                              },
                              expression: "impr.actionCompleteDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.actionAreaEditable,
                              required: true,
                              label: "조치내용",
                              name: "actionContents",
                            },
                            model: {
                              value: _vm.impr.actionContents,
                              callback: function ($$v) {
                                _vm.$set(_vm.impr, "actionContents", $$v)
                              },
                              expression: "impr.actionContents",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-upload", {
                            attrs: {
                              attachInfo: _vm.attachAfterInfo,
                              editable: _vm.actionAreaEditable,
                              label: "개선 후 사진",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }